"use client";

import NavBar from "@public-sites/unified-header/dist/main";
import {
	Breakpoint,
	DropShadowWeight,
	FontWeight,
	ItemsGap,
	NavLogoKind,
	TextSize,
} from "@public-sites/unified-header/dist/types";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import PrivacyBanner from "~/components/privacyBanner/main";
import { sendPayloadToRestAPI } from "~/lib/restAPI";
import messages from "~/resources/messages/en-US/app/error.json";
import layoutMessages from "~/resources/messages/en-US/app/layout.json";
import { IFormData } from "./(normalNavbar)/(diagnostic)/api/error/route";
import ErrorMessageComponent from "./errorMessage";
import Footer from "./footer";

export default function ErrorPage({ error }: { error: Error & { digest?: string } }) {
	// Determine the path this error page is replacing
	const pathname = usePathname();

	// Log to Splunk via a rest API that the error page was shown
	useEffect(() => {
		async function logErrorPageShown() {
			await sendPayloadToRestAPI<IFormData>(
				{
					pathname: pathname,
					digest: error.digest,
				},
				"/api/error",
			);
		}
		void logErrorPageShown();
	}, [pathname, error]);

	// Show the component.
	return (
		<>
			<NavBar
				siteIdentityLinkLogo={NavLogoKind.Careers}
				siteIdentityLinkTooltip={layoutMessages.layout.siteIdentityLinkTooltip.value}
				bottomBarItemsGap={ItemsGap.Small}
				mobileBreakpoint={Breakpoint.lg}
				textSize={TextSize.Small}
				itemsFontWeight={FontWeight.semiBold}
				belowBarDropShadowWeight={DropShadowWeight.Heavy}
				colors={{
					topBarBackground: "#FFFFFF",
					hoverLogoBackground: "#EAE5E1",
					logoFill: "#6B615C",
					text: "#3D2C2B",
					bottomBarSubmenuBackground: "#FFFFFF",
					bottomBarSubmenuItemText: "#6B615C",
					bottomBarBackground: "#EAE5E1",
					submenuBorder: "#EAE5E1",
					hoverLogoFill: "#C62715",
					hoverText: "#C62715",
					selectedLogoBackground: "#C62715",
					selectedHoverLogoBackground: "#C62715",
					selectedLogoFill: "#FFFFFF",
					selectedHoverLogoFill: "#FFFFFF",
					mobileNavigationButton: "#6B7280",
					mobileNavigationPaneBorder: "#334155",
					bottomBarButtonBackground: "#C62715",
					bottomBarButtonHoverBackground: "#750415",
					bottomBarButtonFocusBackground: "#750415",
					bottomBarButtonText: "#FFFFFF",
				}}
				topItems={[]}
				bottomItems={[
					{
						href: "https://epic.avature.net/Careers/Login",
						label: layoutMessages.layout.signInButtonCaption.value,
						tooltip: layoutMessages.layout.signInButtonTooltip.value,
						buttonStyle: true,
						allowedScreenSizeMode: "mobile",
					},
					{
						href: "/jobs/softwaredevelopment",
						label: layoutMessages.layout.softwareDevelopmentLinkCaption.value,
						tooltip: layoutMessages.layout.softwareDevelopmentLinkTooltip.value,
					},
					{
						href: "/lifeatepic",
						label: layoutMessages.layout.lifeAtEpicLinkCaption.value,
						tooltip: layoutMessages.layout.lifeAtEpicLinkTooltip.value,
					},
					{
						href: "/perks",
						label: layoutMessages.layout.benefitsLinkCaption.value,
						tooltip: layoutMessages.layout.benefitsLinkTooltip.value,
					},
					{
						href: "/madison",
						label: layoutMessages.layout.madisonLinkCaption.value,
						tooltip: layoutMessages.layout.madisonLinkTooltip.value,
					},
					{
						href: "/dei",
						label: layoutMessages.layout.deiLinkCaption.value,
						tooltip: layoutMessages.layout.deiLinkTooltip.value,
					},
					{
						href: "/jobs",
						label: layoutMessages.layout.jobsLinkCaption.value,
						tooltip: layoutMessages.layout.jobsLinkTooltip.value,
					},
					{
						href: "https://epic.avature.net/Careers/Login",
						label: layoutMessages.layout.signInButtonCaption.value,
						tooltip: layoutMessages.layout.signInButtonTooltip.value,
						buttonStyle: true,
						allowedScreenSizeMode: "desktop",
					},
				]}
			/>

			<ErrorMessageComponent
				backHomeCaption={messages.error.backHomeCaption.value}
				backHomeTooltip={messages.error.backHomeTooltip.value}
			>
				<div>
					<span>
						{"Something went wrong? You "}
						<i>{"calf"}</i>
						{" to be kidding. "}
					</span>
					<span>{messages.error.errorMessage2.value}</span>
				</div>
			</ErrorMessageComponent>
			<PrivacyBanner privacyPageUrl="https://www.epic.com/privacypolicies" siteKey="careers" />
			<Footer />
		</>
	);
}
